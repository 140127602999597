import * as React from 'react'
import Image from 'next/image'
import NextLink from 'next/link'
import {
    PARTNER_BRAND_PAGE,
    SINGLE_MERCHANT_PARTNER_PGC_HEADER_PAGES,
} from '@/src/modules/pg-plus/constants'
import { TPartnerPayload } from '@/src/modules/partners/types'
import { styled } from '@/src/stitches.config'
import { useRouter } from 'next/router'

const PartnerLogoImage = styled(Image, {
    height: 'auto',
    width: '100%',
    display: 'block',
    maxHeight: '50px',
    '@md': { maxHeight: '73px' },
})

type TLogoProps = {
    isCorporate: boolean
    minWidth?: string | number
    partnerData?: TPartnerPayload
}

const LogoLink = ({
    isCorporate,
    minWidth = '170px',
    partnerData,
}: TLogoProps) => {
    const imageCss = {
        height: 'auto',
        minWidth: minWidth,
        maxWidth: '100%',
        display: 'block',
    }

    const router = useRouter()
    const partnerName: string = partnerData?.partnerName ?? ''
    const partnerLogo = partnerData?.publisherLogoUrl ?? ''
    const singleMerchantPartnerSlug: string =
        partnerData?.store_page_brand_slug ?? ''

    const consumerHomeUrl: string = partnerName ? PARTNER_BRAND_PAGE : '/'

    if (isCorporate) {
        return (
            <NextLink href={'/corporate'}>
                <Image
                    src="/logo-horizontal-corporate.svg"
                    alt="PerfectGift Corporate logo"
                    height={93}
                    width={292}
                    style={imageCss}
                    priority
                />
            </NextLink>
        )
    }

    if (singleMerchantPartnerSlug) {
        if (
            partnerLogo &&
            !SINGLE_MERCHANT_PARTNER_PGC_HEADER_PAGES.includes(router.pathname)
        ) {
            return (
                <div style={{ maxWidth: '100%', height: 'auto' }}>
                    <PartnerLogoImage
                        src={partnerLogo}
                        alt={`${partnerName} Logo`}
                        height={73}
                        width={300}
                        priority
                    />
                </div>
            )
        }

        return (
            <Image
                src="/logo-horizontal.svg"
                alt="PerfectGift logo"
                height={73}
                width={300}
                style={imageCss}
                priority
            />
        )
    }

    return (
        <NextLink href={consumerHomeUrl}>
            <Image
                src="/logo-horizontal.svg"
                alt="PerfectGift logo"
                height={73}
                width={300}
                style={imageCss}
                priority
            />
        </NextLink>
    )
}

export default LogoLink
