import { IconButton } from '@/src/common/components/elements/Buttons/IconButton'
import Text from '@/src/common/components/elements/Text/Text'
import { styled, theme } from '@/src/stitches.config'
import Link from 'next/link'

export const CartItemContainer = styled('div', {
    display: 'flex',
    fd: 'row',
    pt: 25,
    variants: {
        border: {
            borderBottom: {
                borderBottom: `1px solid ${theme.colors.mediumGray}`,
                pb: 10,
            },
        },
    },
    '.gift-box-wrapper': {
        pb: 0,
    },
    '.capitalize': {
        textTransform: 'capitalize',
    },
    '.bold': {
        fontWeight: 'bold',
    },
})

export const LeftContentContainer = styled('div', {
    display: 'flex',
    position: 'relative',
    fd: 'column',
    width: '30%',
})

export const RightContentContainer = styled('div', {
    display: 'flex',
    fd: 'column',
    flex: 1,
    px: 10,
    ml: 5,
    height: 'min-content',
    '@sm': {
        py: 5,
        ml: 10,
    },
})

export const StandardWrapper = styled('div', {
    width: '100%',
    display: 'flex',
    mb: 5,
})

export const SpaceBetweenWrapper = styled(StandardWrapper, {
    justifyContent: 'space-between',
    gap: '10%',
})

export const ColumnsContainer = styled(StandardWrapper, {
    pr: 0,
    fd: 'column',
    '@media (min-width: 1250px)': {
        fd: 'row',
        gap: '5%',
        jc: 'space-between',
    },
})

export const CardDetailsContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    mr: '4%',
})

export const LineItemText = styled(Text, {
    fontSize: 14,
    lh: 1.15,
    fontWeight: 'normal',
    '.title-case': {
        textTransform: 'capitalize',
    },
    variants: {
        fontSize: {
            smaller: {
                fontSize: 13,
            },
        },
        fontWeight: {
            bold: {
                fontWeight: 800,
            },
        },
        fontColor: {
            dark: {
                color: '$dark',
            },
            darkest: {
                color: '$darkest',
            },
            primary: {
                color: '$primary',
            },
        },
        textAlign: {
            left: {
                textAlign: 'left',
            },
            right: {
                textAlign: 'right',
            },
        },
        marginBottom: {
            5: {
                mb: 5,
            },
        },
    },
    defaultVariants: {
        fontColor: 'dark',
        textAlign: 'left',
    },
})

export const ClickableWrapper = styled('div', {
    cursor: 'pointer',
    width: 'fit-content',
})

export const CardFeesContainer = styled('div', {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '@media (min-width: 1250px)': {
        alignItems: 'flex-end',
    },
})

export const MobileWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 20,
    width: '100%',
    '.capitalize': {
        textTransform: 'capitalize',
    },
})

export const PromoButton = styled(IconButton, {
    color: '$primary',
    cursor: 'pointer',
})

export const RemoveButton = styled(IconButton, {
    color: '$primary',
    cursor: 'pointer',
    fontSize: 19,
    lineHeight: 1.5,
    padding: 10,
    height: 'auto !important',
    width: 'max-content !important',
})

export const EditPencil = styled(Link, {
    color: '$primary !important',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: 19,
    lineHeight: 1.5,
    marginLeft: 'auto',
    padding: 10,
    width: 'fit-content',
})

export const ActionButtonsWrapper = styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gap: '12%',
})

export const Separator = styled('hr', {
    m: '15px 9px 0',
    borderTop: '1px solid $mediumGray',
})

export const ExpandDetails = styled('div', {
    marginTop: 'auto',
    cursor: 'pointer',
    '& p': {
        textDecoration: 'underline',
        color: '$primary',
        fontSize: '.8rem',
    },
})

export const BaseContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    '@md': {
        marginLeft: 'auto',
        maxWidth: 620,
    },
})
