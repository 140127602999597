import {
    TPartnerPayload,
    TWhiteLabelRewardData,
} from '@/src/modules/partners/types'
import convertDecimalToNumber from '@/src/common/utilities/convert/convertDecimalToNumber'
import { ILineItem } from '@/src/modules/cart/ICart'

// used to display earned value in purchase page
export function determinePartnerIncentiveValues(
    partnerData: TPartnerPayload,
    denomination: string
): string {
    const denomAsNumber: number = Number(denomination)
    const {
        isLoyaltyProgram,
        loyaltyUnit,
        loyaltyConversionRate = 100,
        brandIncentive,
    } = partnerData

    const { number: incentiveAsNumber, multipliedBy100: incentiveTimes100 } =
        convertDecimalToNumber(brandIncentive, true)

    // FOR POINTS / MILES
    if (isLoyaltyProgram) {
        const adjustedIncentiveRate: number =
            incentiveAsNumber * (loyaltyConversionRate / 100)
        const adjustedUnit: string =
            adjustedIncentiveRate > 1 ? loyaltyUnit : loyaltyUnit.split('s')[0]

        if (!denomination) {
            return `Earn ${adjustedIncentiveRate} ${adjustedUnit} per dollar spent`
        }

        const earnedValue: number = denomAsNumber * adjustedIncentiveRate

        return `You will earn ${earnedValue} ${loyaltyUnit} from this purchase`
    }

    // FOR DISCOUNT
    if (denomination) {
        const discountAsValue = (denomAsNumber * incentiveAsNumber).toFixed(2)

        return `You will save $${discountAsValue} on this gift card`
    }

    return `A ${incentiveTimes100}% discount will be applied to your purchase`
}

export function determineValueEarned(
    denomination: string,
    itemPrice: string,
    isLoyaltyProgram: boolean,
    brandIncentive: number,
    loyaltyConversionRate: number
): number {
    const denomAsNumber = Number(denomination)

    // return total points/miles earned
    if (isLoyaltyProgram) {
        const adjustedIncentiveRate: number =
            brandIncentive * (loyaltyConversionRate / 100)
        return denomAsNumber * adjustedIncentiveRate
    }

    // return total discount value
    const discountPriceAsNumber: number = Number(itemPrice)
    return Number((denomAsNumber - discountPriceAsNumber).toFixed(2))
}

// used to display earned value in cart item
export function determineValueEarnedForCart(
    whiteLabelRewards: TWhiteLabelRewardData | undefined
): string {
    const hasRewardValue: boolean = (whiteLabelRewards?.reward_value ?? 0) > 0

    if (!whiteLabelRewards || !hasRewardValue) {
        return ''
    }

    const {
        is_loyalty_program: isLoyaltyProgram,
        incentive_type: loyaltyUnit,
        reward_value: rewardValue,
    } = whiteLabelRewards

    // FOR POINTS / MILES
    if (isLoyaltyProgram) {
        return `Earns ${rewardValue} ${loyaltyUnit} after purchase`
    }

    // FOR DISCOUNT
    return `You save $${rewardValue.toFixed(2)}`
}

// used to resolve cart data
export function determineItemPrice(
    denomination: string,
    brandIncentive: number
): string {
    const denomAsNumber = Number(denomination)

    // if converted value is not a number, return denom
    if (isNaN(denomAsNumber)) {
        throw new Error('invalid denomination')
    }

    const { number: discount } = convertDecimalToNumber(brandIncentive, false)

    // returns total discount (ex: 1.00)
    const discountAsValue = denomAsNumber * discount

    // takes the denom, subtracts the discount, and returns the new amount for item price
    return (denomAsNumber - discountAsValue).toFixed(2)
}

export function determineIncentiveTotalTextForSummary(
    partnerData: TPartnerPayload | undefined,
    totalIncentiveAmount: number,
    whiteLabelItems: ILineItem[] | undefined
) {
    if (
        !partnerData ||
        !whiteLabelItems ||
        partnerData?.programConfig?.store_page_brand_id
    ) {
        return { text: '', decorator: '', amount: 0 }
    }

    // FOR POINTS / MILES
    const loyaltyUnit: string =
        whiteLabelItems[0]?.white_label_rewards?.incentive_type ?? ''
    const isLoyaltyProgram: boolean = ['miles', 'points'].includes(
        loyaltyUnit.toLowerCase()
    )

    if (isLoyaltyProgram) {
        return {
            text: `Total ${loyaltyUnit} Earned`,
            decorator: '+',
            amount: totalIncentiveAmount,
        }
    }

    // FOR DISCOUNT
    return {
        text: 'Total Discount',
        decorator: '-',
        amount: `$${totalIncentiveAmount.toFixed(2)}`,
    }
}

// used to determine total incentive earned for cart summary display
export function determineTotalIncentiveAmount(lineItems: ILineItem[]) {
    if (!lineItems) {
        return 0
    }

    // go through each item and total their rewards
    return lineItems.reduce(function (total: any, item: any) {
        const { reward_value } = item.white_label_rewards

        return total + reward_value
    }, 0)
}
