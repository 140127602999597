import { SALES_FLOW_CAMPAIGN } from '@/src/modules/chat-bot/constants'

export function determineIfScriptExists(scriptId: string) {
    return !!document.getElementById(scriptId)
}

// Function to check if the page should be excluded entirely
export function isExcludedPage(pagePath: string) {
    // hide chat on these paths all the time
    const generalExcludedPaths = [
        '/checkout',
        '/predesigned-visa-gift-card',
        '/personalized-visa-gift-card',
        '/virtual-visa-gift-cards',
        '/visa-gift-card-box',
        '/lp',
        '/brands',
        '/perfectgift-plus/build',
    ]
    const isAlwaysExcluded: boolean = generalExcludedPaths.some(function (
        path
    ) {
        return pagePath.startsWith(path)
    })

    // hide chat on these mobile paths
    const excludedMobilePaths = ['/lp', '/corporate']
    const isExcludedOnMobile: boolean = excludedMobilePaths.some(function (
        path
    ) {
        return pagePath.startsWith(path) && isMobileDevice()
    })

    return isAlwaysExcluded || isExcludedOnMobile
}

// Function to check if the device is mobile
function isMobileDevice() {
    return window.innerWidth < 1024 // Adjust the width as needed for your mobile breakpoint
}

// trigger an ada campaign
function triggerAdaCampaign(campaignId: string) {
    if (typeof window !== 'undefined' && window.adaEmbed) {
        window.adaEmbed.triggerCampaign(campaignId)
    }
}

function closeAdaCampaign(campaignId: string) {
    if (typeof window !== 'undefined' && window.adaEmbed) {
        window.adaEmbed.closeCampaign(campaignId)
    }
}

export function handleAdaCampaign(pagePath: string) {
    if (pagePath.includes('/corporate') || pagePath.includes('/lp')) {
        triggerAdaCampaign(SALES_FLOW_CAMPAIGN)
    } else {
        closeAdaCampaign(SALES_FLOW_CAMPAIGN)
    }
}
